import React, {useState, useEffect, useContext} from "react";
import {
    Grid,
    Paper,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    FormLabel,
    Button,
    Switch,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {PersonelContext} from '../../context/personelContext';
import {LocalizationContext} from '../../context/localizationContext';
import {AuthContext} from '../../context/authContext';
import {OrgContext} from '../../context/orgContext';
import {permLevel, emailRegex} from '../../functional/etc';
import Dialog from '../../components/Dialog';
import {ArrowBackIos} from '@material-ui/icons';
import MaskedInput from '../../components/maskedInput'
import {countries} from "../../functional/etc";
import APICall from "../../functional/APIRequests";

const useStyles = makeStyles(theme => ({
    paperTitle: {
        width: "100%",
        padding: 20,
        marginBottom: 20,
    },
    buttonGroup: {
        display: "flex",
        justifyContent: "flex-end"
    },
    container: {
        display: "flex",
        justifyContent: "center"
    },
    paper: {
        width: "100%",
        padding: 20
    },
    orgConnectionsBox: {
        padding: 10
    },
    orgName: {
        paddingLeft: 10
    }
}));
const OrganizationPersonel = props => {
    const classes = useStyles();
    const {
        isMRO,
        setIsMRO,
        getPersonelById,
        postPersonel,
        patchStatusPersonel,
        deletePersonel,
        setPersonnelAsMRO
    } = useContext(PersonelContext)
    const {loading} = useContext(AuthContext);
    const {getTranslation} = useContext(LocalizationContext);
    const {selectedOrg} = useContext(OrgContext);

    const [showDialog, SetShowDialog] = useState(false);
    let selectedOrgId = !isNaN(props.match.params.id) ? Number(props.match.params.id) : Number(selectedOrg.id);
    document.title = getTranslation('staff_browser_title') + ' | Prodiagnostics';
    const [data, setData] = useState({
        name: '',
        pnr: '',
        email: '',
        cellphone: '',
        method_email: '0',
        method_sms: '0',
        hsaid: '',
        access: {},
        country: "",
        ssn_type: selectedOrg.ssn_type
    })
    const [active, setActive] = useState(true);
    const [invalidPnrError, setInvalidPnrError] = useState(false);
    const isNew = props.match.params.idPersonel.toLowerCase() === 'new';
    const fetchData = () => {
        getPersonelById({idOrg: selectedOrgId, idPersonel: props.match.params.idPersonel}).then(res => {
            setIsMRO(res.access.mro);
            let modPnr = res.pnr;
            if (modPnr && modPnr.length > 0) {
                modPnr = [modPnr.slice(0, 8), '-', modPnr.slice(8)].join('');
            }
            let access;

            if (res.access.admin && Object.keys(res.access).length > 1) {
                access = {admin: true};
            } else {
                access = res.access;
            }

            let hsaid = res.hsaid ? res.hsaid : '';
            setData({...res, access: access, pnr: modPnr, hsaid: hsaid, ssn_type: selectedOrg.ssn_type})
            setActive(!res.deactivated_at)
        })
    }

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    }
    const getPerm = () => {
        return permLevel(selectedOrg);
    }
    const validPNR = () => {
        if (data.pnr.length === 12 || (data.pnr.length === 13 && data.pnr.slice(8, 9) === '-')) {
            return true;
        } else {
            return false
        }
    }
    const formValidator = () => {
        let error = [];
        Object.keys(data).forEach((key) => {
            var value = data[key];
            switch (key) {
                case 'email':
                    if (!value || value.length === 0 || !emailRegex.test(data.email)) {
                        error.push('email')
                    }
                    break;
                case 'cellphone':
                    if (!value || value.length === 0) {
                        error.push('cellphone')
                    }
                    break;
                case 'name':
                    if (!value || value.length === 0) {
                        error.push('name')
                    }
                    break;
                case 'pnr':
                    if ((value && value.length === 0) && !invalidPnrError) {
                        error.push('pnr')
                    }
                    /* if ((value && value.length > 0)) {
                       error.push('pnr')
                     }*/
                    break;
                default:
                    return true;
            }
        });
        return error.length === 0;
    }
    const onSubmit = (e) => {
        try {
            e.preventDefault()
        } catch (err) {
        }
        ;
        if (formValidator()) {
            if (!isNew && data.access.mro && !isMRO) {
                setPersonnelAsMRO({orgId: selectedOrgId, idPersonel: props.match.params.idPersonel})
            }

            let form = new FormData();
            form.append("data[name]", data.name);
            form.append("data[pnr]", data.pnr);
            form.append("data[email]", data.email);
            form.append("data[cellphone]", data.cellphone);
            form.append("data[method_sms]", data.method_sms);
            form.append("data[method_email]", data.method_email);
            form.append("data[hsaid]", (data.hsaid && data.hsaid.length > 0) ? data.hsaid : "")

            if (data.access.admin) {
                form.append("data[access][admin]", data.access.admin);
            }
            if (data.access.mro) {
                form.append("data[access][mro]", data.access.mro);
            }
            if (data.access.consult) {
                form.append("data[access][consult]", data.access.consult);
            }
            if (data.access.customer) {
                form.append("data[access][customer]", data.access.customer);
            }
            if (data.access.superuser) {
                form.append("data[access][superuser]", data.access.superuser);
            }

            if (isNew) {
                form.append("data[ssn_type]", data.ssn_type);
            }
            // for (let [key, value] of form.entries()) {
            //     console.log(key, value);
            // }
            // FIXME: missing permissions!!
            postPersonel({idOrg: selectedOrgId, idPersonel: props.match.params.idPersonel}, form, false).then((res) => {
                if (res) {
                    props.history.goBack();
                }
            })
        }
    }
    const changeStatus = (e) => {
        setActive(e.target.checked)
        patchStatusPersonel({idOrg: selectedOrgId, idPersonel: props.match.params.idPersonel, status: e.target.checked})
    }
    const delPersonel = (e) => {
        SetShowDialog(true)
        // return true;
    }
    const dialogCallback = (params) => {
        deletePersonel({idOrg: selectedOrgId, idPersonel: props.match.params.idPersonel}).then(() => {
            props.history.goBack();
        })
    }

    const checkValidPnr = () => {
        APICall({
            url: `customer/${selectedOrgId}/ssn/validate?ssn=${data.pnr}&lang=${data.ssn_type}`,
            verb: "get",
            server: "api"
        })
            .then(res => {
                setInvalidPnrError(!res.data);
            })
    }

    // eslint-disable-next-line
    useEffect(() => {
        if (!isNew) {
            fetchData()
        } else {
            setData(prevState => ({
                ...prevState,
                access: {customer: true},
            }))
        }
    }, [])

    useEffect(() => {
        if (data.ssn_type && data.pnr && data.pnr.length > 0) {
            checkValidPnr();
        }
    }, [data.ssn_type])

    const handleBack = () => {
        props.history.goBack();
    }
    const updatePNR = (value) => {
        setData({...data, pnr: value})
    }

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} sm={10}>
                <Paper className={classes.paperTitle} elevation={1}>
                    <Button
                        style={{margin: '0px 5px', float: 'right'}}
                        variant="outlined"
                        color="primary"
                        onClick={handleBack}
                        startIcon={<ArrowBackIos style={{height: 12, width: 12}}/>}
                    >{getTranslation('generic_button_back_to_list')}</Button>
                    <Typography variant="h6" gutterBottom>
                        {getTranslation('personel_edit_title')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {getTranslation('personel_edit_subtitle')}
                    </Typography>
                </Paper>
                {showDialog &&
                    <Dialog
                        dialogData={{}}
                        open={showDialog}
                        setOpen={SetShowDialog}
                        callback={dialogCallback}
                        title={getTranslation('personel_delete_dialog_title')}
                        message={getTranslation('personel_delete_dialog_message')}
                        cancelLabel={getTranslation('generic_button_cancel')}
                        submitLabel={getTranslation('generic_button_delete')}
                    />
                }
                <Paper className={classes.paper} elevation={1}>
                    <Grid container>
                        {(!selectedOrg.customer && !selectedOrg.mro && !selectedOrg.consult && !isNew) &&
                            <Grid item xs={12}>
                                <FormGroup row style={{float: 'right', padding: 0}}>
                                    <FormLabel
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            paddingRight: 10,
                                            fontSize: 14
                                        }}
                                        component="legend"
                                    >
                                        {getTranslation('generic_string_active')}
                                    </FormLabel>
                                    <Switch
                                        checked={active}
                                        onChange={changeStatus}
                                        color="primary"
                                    />
                                </FormGroup>
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <TextField
                                variant="filled"
                                disabled={loading}
                                label={getTranslation('organization_personel_form_label_name')}
                                name='name'
                                value={data.name}
                                onChange={handleChange}
                                error={!data.name}
                                helperText={!data.name ? getTranslation('generic_error_required_field') : ''}
                            />
                        </Grid>
                        <Grid item xs={isNew ? 4 : 6}>
                            <TextField
                                variant="filled"
                                value={data.pnr}
                                onChange={e => updatePNR(e.target.value)}
                                disabled={loading || (getPerm() < 3 && props.match.params.idPersonel !== 'new')}
                                label={data.ssn_type === "se" ? getTranslation('organization_personel_form_label_personal_id_se') : getTranslation('organization_personel_form_label_personal_id')}
                                error={!data.pnr || invalidPnrError}
                                helperText={!data.pnr ? getTranslation('generic_error_required_field') : (invalidPnrError ? "organization_personel_form_invalid_pnr_error" : "")}
                                onBlur={checkValidPnr}
                            />

                            {/*<MaskedInput
                value={data.pnr}
                updatePNR={updatePNR}
                disabled={loading || (getPerm() < 3 && props.match.params.idPersonel !== 'new')}
                label={getTranslation('organization_personel_form_label_personal_id')}
              />*/}
                        </Grid>
                        {isNew &&
                            <Grid item xs={2}>
                                <FormControl className='fixSelectAndDates'>
                                    <InputLabel
                                        id="newLang">{getTranslation('change_country_select_label')}</InputLabel>
                                    <Select
                                        labelId="country"
                                        disabled={loading || (getPerm() < 3 && props.match.params.idPersonel !== 'new')}
                                        value={data.ssn_type}
                                        name='ssn_type'
                                        onChange={handleChange}
                                    >
                                        {countries.map(al =>
                                            <MenuItem key={al.value} value={al.value}>{al.title}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <TextField

                                variant="filled"
                                disabled={loading}
                                error={!data.email || (data.email.length > 0 && !emailRegex.test(data.email))}
                                label={getTranslation('organization_personel_form_label_email')}
                                name='email'
                                value={data.email}
                                onChange={handleChange}
                                helperText={
                                    !data.email ? getTranslation('generic_error_required_field') :
                                        (data.email.length > 0 && !emailRegex.test(data.email)) ? getTranslation('generic_email_regex_fail')
                                            : ''}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="filled"
                                disabled={loading}
                                // error={false}
                                label={getTranslation('organization_personel_form_label_phone')}
                                name='cellphone'
                                value={data.cellphone}
                                onChange={handleChange}
                                error={!data.cellphone}
                                helperText={!data.cellphone ? getTranslation('generic_error_required_field') : ''}
                            />
                        </Grid>
                        {getPerm() >= 2 && !selectedOrg.mro && !selectedOrg.consult &&
                            <Grid item xs={6}>
                                <FormControl className='fixSelectAndDates'>
                                    <InputLabel
                                        id="accessLevel">{getTranslation('organization_personel_form_label_permissions')}</InputLabel>
                                    <Select
                                        disabled={loading}
                                        labelId="accessLevel"
                                        value={isMRO ? 2 : data.access.superuser ? 1 : data.access.admin ? 4 : data.access.consult ? 3 : 5}
                                        onChange={e => {
                                            if (e.target.value === 2) {
                                                setIsMRO(true);
                                                setData({
                                                    ...data,
                                                    access: {
                                                        mro: true,
                                                    },
                                                })
                                            } else if (e.target.value === 1) {
                                                setIsMRO(false);
                                                setData({
                                                    ...data,
                                                    access:
                                                        {
                                                            superuser: true
                                                        },
                                                })
                                            } else if (e.target.value === 3) {
                                                setIsMRO(false);
                                                setData({
                                                    ...data,
                                                    access:
                                                        {
                                                            consult: true
                                                        },
                                                })
                                            } else if (e.target.value === 4) {
                                                setIsMRO(false);
                                                setData({
                                                    ...data,
                                                    access:
                                                        {
                                                            admin: true
                                                        },
                                                })
                                            } else if (e.target.value === 5) {
                                                setIsMRO(false);
                                                setData({
                                                    ...data,
                                                    access:
                                                        {
                                                            customer: true
                                                        },
                                                })
                                            }
                                        }}
                                    >
                                        {selectedOrgId !== 1 &&
                                            <MenuItem value={4}>{getTranslation('generic_admin_label')}</MenuItem>
                                        }
                                        {selectedOrgId !== 1 &&
                                            <MenuItem value={5}>{getTranslation('generic_customer_label')}</MenuItem>
                                        }
                                        {selectedOrgId === 1 &&
                                            <MenuItem value={1}>{getTranslation('generic_super_admin_label')}</MenuItem>
                                        }
                                        {getPerm() > 1 && selectedOrg.id === '1' &&
                                            <MenuItem value={2}>{getTranslation('generic_mro_label')}</MenuItem>
                                        }
                                        {getPerm() > 1 && selectedOrg.id === '1' &&
                                            <MenuItem value={3}>{getTranslation('generic_consultant_label')}</MenuItem>
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <TextField
                                variant="filled"
                                disabled={loading}
                                // error={false}
                                label={getTranslation('organization_personel_form_label_hsaid')}
                                name='hsaid'
                                value={data.hsaid}
                                onChange={handleChange}
                            />
                        </Grid>
                        {getPerm() > 2 && (data.organisations && data.organisations.length > 0) &&
                            <Grid item xs={12} className={classes.orgConnectionsBox}>
                                <Typography variant="subtitle2" gutterBottom>
                                    {getTranslation('organization_personel_form_customer_connections')}
                                </Typography>
                                {data.organisations.map((item, index) =>
                                    <Typography key={index} variant="body2" gutterBottom className={classes.orgName}>
                                        {`${item.name} - ${item.orgnr}`}
                                    </Typography>
                                )}
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormLabel
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            paddingRight: 10
                                        }}
                                        component="legend"
                                    >
                                        {getTranslation('organization_personel_form_notified_methods_label')}
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{marginLeft: 5}}
                                        control={
                                            <Checkbox
                                                disabled={loading}
                                                checked={data.method_sms === '1'}
                                                onChange={(e) => setData({
                                                    ...data,
                                                    method_sms: e.target.checked ? '1' : '0'
                                                })}
                                                value="method_sms"
                                            />
                                        }
                                        label={getTranslation('organization_personel_form_notified_methods_sms')}
                                    />
                                    <FormControlLabel
                                        style={{marginLeft: 5}}
                                        control={
                                            <Checkbox
                                                disabled={loading}
                                                checked={data.method_email === '1'}
                                                onChange={(e) => setData({
                                                    ...data,
                                                    method_email: e.target.checked ? '1' : '0'
                                                })}
                                                value="method_email"
                                            />
                                        }
                                        label={getTranslation('organization_personel_form_notified_methods_email')}
                                    />
                                    {data.method_email === '0' && data.method_sms === '0' &&
                                        <FormHelperText>{getTranslation('generic_error_required_field')}</FormHelperText>}
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid container className={classes.buttonGroup}>
                            <Button
                                style={{margin: '0px 5px'}}
                                variant="outlined"
                                color="primary"
                                onClick={() => props.history.goBack()}
                            >{getTranslation('generic_button_back')}</Button>
                            {((selectedOrg.admin || selectedOrg.superuser) && !isNew) &&
                                < Button
                                    style={{margin: '0px 5px'}}
                                    variant="contained"
                                    color="primary"
                                    onClick={delPersonel}
                                >{getTranslation('generic_button_delete')}</Button>
                            }
                            <Button
                                style={{margin: '0px 5px'}}
                                variant="contained"
                                color="primary"
                                disabled={
                                    !data.name ||
                                    !data.cellphone ||
                                    !data.email || data.email.length === 0 || !emailRegex.test(data.email) ||
                                    !data.pnr || invalidPnrError
                                }
                                onClick={onSubmit}
                            >{getTranslation('generic_button_save')}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};
export default OrganizationPersonel;
