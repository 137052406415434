import React, {useEffect, useState} from "react";
import {Html5Qrcode} from "html5-qrcode";

const BarcodeScanner = (props) => {

    const [cameraId, setCameraId] = useState("");
    const [html5QrCode, setHtml5QrCode] = useState(null);

    const {handleCapture, setIsScanning, checkBarcodeInUse} = props;

    const handleStartCamera = () => {
        html5QrCode.start(
            cameraId,
            {
                fps: 10,
                qrbox: 250,
            },
            (qrCodeMessage) => {
                handleCapture(qrCodeMessage);
                checkBarcodeInUse(qrCodeMessage)
                handleStopCamera();
            }
        );
    };

    const handleStopCamera = () => {
        try {
            html5QrCode.stop().then(() => {
                html5QrCode.clear();
                setIsScanning(false);
            });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const getBackCamerasWithZoom = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(
                    (device) => device.kind === "videoinput"
                );

                // Filter back-facing cameras based on the label
                const backFacingCameras = videoDevices.filter(
                    (device) =>
                        device.label.toLowerCase().includes("back") ||
                        device.label.toLowerCase().includes("environment")
                );

                if (backFacingCameras.length === 0) {
                    setCameraId(videoDevices[0].deviceId);
                    return;
                }

                let cameraWithMaxZoom = null;
                let maxZoomValue = 1;

                // Check zoom capabilities for each back camera
                for (let camera of backFacingCameras) {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        video: {deviceId: {exact: camera.deviceId}},
                    });

                    const videoTrack = stream.getVideoTracks()[0];
                    const capabilities = videoTrack.getCapabilities();

                    // If the camera supports zoom, check the max zoom value
                    if (capabilities.zoom) {
                        const currentMaxZoom = capabilities.zoom.max;
                        if (currentMaxZoom > maxZoomValue) {
                            maxZoomValue = currentMaxZoom;
                            cameraWithMaxZoom = camera;
                        }
                    }

                    // Stop the video track after checking capabilities
                    videoTrack.stop();
                }

                if (cameraWithMaxZoom) {
                    setCameraId(cameraWithMaxZoom.deviceId);
                    console.log(
                        `Selected camera: ${cameraWithMaxZoom.label}, Max Zoom: ${maxZoomValue}`
                    );
                } else {
                    // Fallback to the first available camera if none support zoom
                    setCameraId(backFacingCameras[0].deviceId);
                }
            } catch (err) {
                console.error("Error accessing devices:", err);
            }
        };

        getBackCamerasWithZoom();
    }, []);

    useEffect(() => {
        if (cameraId) {
            handleStartCamera();
        }
    }, [cameraId])

    useEffect(() => {
        let _html5QrCode = new Html5Qrcode("reader");
        setHtml5QrCode(_html5QrCode);

    }, []);

    return (
        <div id="reader" style={{width:600}}></div>
    )
}

export default BarcodeScanner;