import React, {useContext, useEffect} from "react";
import {Html5QrcodeScanner} from "html5-qrcode";
import {Html5Qrcode} from "html5-qrcode";
import {TestcaseContext} from "../context/testcaseContext";

const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props, cameraId) => {
    let config = {};
    if (props.fps) {
        config.fps = props.fps;
    }
    if (props.qrbox) {
        config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
    }

    if(cameraId) {
        config.videConstraints = {deviceId: cameraId}
    }
    return config;
};

const Html5QrcodePlugin = (props) => {

    const { setCameras, cameras, selectedCamera, setSelectedCamera} = useContext(TestcaseContext);

    useEffect(() => {
        Html5Qrcode.getCameras()
            .then(devices => {
                if(devices && devices.length > 0) {
                    setCameras(devices);
                    const lastCamera = devices[devices.length - 1];
                    setSelectedCamera(lastCamera);
                }
            })
    }, []);

    useEffect(() => {
        if(!selectedCamera) {
            return
        }
        console.log("here2")

        // when component mounts
        const config = createConfig(props, selectedCamera.id);
        const verbose = props.verbose === true;
        // Suceess callback is required.
        if (!props.qrCodeSuccessCallback) {
            throw "qrCodeSuccessCallback is required callback.";
        }
        const html5QrcodeScanner = new Html5QrcodeScanner(
            qrcodeRegionId,
            config,
            verbose
        );
        html5QrcodeScanner.render(
            props.qrCodeSuccessCallback,
            props.qrCodeErrorCallback
        );

        // cleanup function when component will unmount
        return () => {
            html5QrcodeScanner.clear().catch((error) => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        };
    }, [selectedCamera]);

    useEffect(() => {
        if(!selectedCamera) {
            return
        }
        console.log("here 3")

       /* const qrcodeRegion = document.getElementById(qrcodeRegionId);
        qrcodeRegion.style.border = "none";
        qrcodeRegion.style.width = "600px";

        /!*let qrcode_scan_region = document.getElementById("html5qr-code-full-region__scan_region");
        let flipIcon = document.createElement("img");
        flipIcon.src = "/images/basic-change-camera-outline.svg";
        flipIcon.alt = "flip_camera_icon";
        flipIcon.style.position = "absolute";
        qrcode_scan_region.appendChild(flipIcon)*!/


        let qrcode_dashboardRegion = document.getElementById("html5qr-code-full-region__dashboard");
        //qrcode_dashboardRegion.style.visibility = "hidden";
        qrcode_dashboardRegion.style.display = "none";

        const images = document.querySelectorAll("#html5qr-code-full-region img");
        images.forEach((img) => {
            img.style.display = "none";
        });

        const images2 = document.querySelectorAll("#html5qr-code-full-region__scan_region img");
        images2.forEach((img) => {
            img.style.display = "none";
        });*/
    }, [selectedCamera]);
    console.log(selectedCamera)
    return <div id={qrcodeRegionId}/>
};

export default Html5QrcodePlugin;
